<template>
  <div class="py-4 py-lg-6 bg-primary">
    <div class="container">
      <div class="row">
        <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
          <div class="d-lg-flex align-items-center justify-content-between">
            <!-- Content -->
            <div class="mb-4 mb-lg-0">
              <h1 class="text-white mb-1">Thêm trang</h1>
              <p class="mb-0 text-white lead">
                Điền thông tin rồi ấn Thêm mới để thêm trang
              </p>
            </div>
            <div>
              <router-link class="btn btn-white" :to="{name: 'MySchool', query: {tab: 'page'}}">
                Danh sách trang
              </router-link>&nbsp;
              <button type="button" v-on:click="onSubmit"
                      class="btn btn-success ">Thêm
                mới
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pb-12">
    <div class="container">
      <div id="pageForm" class="bs-stepper">
        <div class="row">
          <div class="offset-lg-1 col-lg-10 col-md-12 col-12">
            <div class="bs-stepper-content mt-5">
              <form v-on:submit.prevent="">
                <div class="card mb-3 ">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="mb-3">
                          <label for="name" class="form-label">Tiêu đề trang</label>
                          <input v-model="page.name" id="name" class="form-control" type="text" required/>
                        </div>
                        <div class="mb-3" v-if="parseInt(page.content_mode) === 0">
                          <label class="form-label">Nội dung</label>
                          <Editor v-model:content="page.content"/>
                        </div>
                        <div class="mb-3" v-if="parseInt(page.content_mode) === 1">
                          <label class="form-label">Nội dung (HTML)</label>
                          <textarea rows="9" placeholder="Dán nội dung HTML vào đây..." class="form-control" v-model="page.content_html"></textarea>
                        </div>
                        <div class="mb-3 mt-3 d-flex align-middle">
                          <label class="form-label">Kiểu nội dung</label>
                          <div class="ms-3">
                            <div class="d-inline-flex">
                              <div class="form-check me-3">
                                <input type="radio" id="mode0" v-model="page.content_mode" value=0
                                       class="form-check-input">
                                <label class="form-check-label" for="mode0">Mặc định</label>
                              </div>
                              <div class="form-check">
                                <input type="radio" id="mode1" v-model="page.content_mode" value=1
                                       class="form-check-input">
                                <label class="form-check-label" for="mode1">HTML</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 mt-3 d-flex align-middle">
                          <label class="form-label">Trạng thái</label>
                          <div class="ms-3">
                            <div class="d-inline-flex">
                              <div class="form-check me-3">
                                <input type="radio" id="statusActive" v-model="page.status" value=1
                                       class="form-check-input">
                                <label class="form-check-label" for="statusActive">Xuất bản</label>
                              </div>
                              <div class="form-check">
                                <input type="radio" id="statusInactive" v-model="page.status" value=0
                                       class="form-check-input">
                                <label class="form-check-label" for="statusInactive">Nháp</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <Thumbnail purpose="setThumbnailPage" :thumbnail="page.thumbnail"/>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="messageModal" :type="messageModalType" :message="messageModalMessage" title="Thêm người dùng"
         :message-detail="messageModalMessageDetail"/>

  <Media :mediaMode="mediaMode" v-on:setMedia="handleSetMediaEvent"/>
</template>

<script>
import Media from "@/components/Media";
import Thumbnail from "@/components/Thumbnail";
import Editor from "@/components/Editor";
import Modal from "@/components/Modal";
import ApiService from "@/core/services/api.service";
import {openModal} from "@/core/services/utils.service";

export default {
  name: 'AddPage',
  components: {
    Media,
    Thumbnail,
    Modal,
    Editor
  },
  data() {
    return {
      page: {
        name: "",
        content: "",
        content_html: "",
        content_mode: "0",
        status: 1,
        thumbnail: null,
        thumbnail_id: null
      },
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: ""
    }
  },
  methods: {
    handleSetMediaEvent(media, purpose) {
      if (purpose === "setThumbnailPage") {
        this.page.thumbnail_id = parseInt(media.id);
        this.page.thumbnail = media;
      }
    },
    onSubmit() {
      let query = `mutation($name: String!, $content: String, $content_html: String, $content_mode: Int, $status: Int, $thumbnail_id: Int) {
        createPage(input: {
          name: $name
          content: $content
          content_html: $content_html
          content_mode: $content_mode
          status: $status
          thumbnail_id: $thumbnail_id
        }) {
          code
        }
      }`;

      this.page.content_mode = parseInt(this.page.content_mode);

      ApiService.graphql(query, this.page)
          .then(({data}) => {
            if (data.data && data.data.createPage) {
              this.messageModalMessage = "Tạo trang thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              this.$router.push({name: 'MySchool', query: {tab: 'page'}})
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    }
  },
  mounted() {

  }
}
</script>
